<template>
  <v-container fluid fill-height class="bg">
    <v-row justify-center align-center>
      <v-col cols="12">
        <form ref="form" lazy-validation @submit.prevent="signUp" @keyup.native.enter="signUp">
          <v-card class="mx-auto pa-8" max-width="446" elevation="0">
            <v-card-title class="pa-0 mb-4">
              <v-img
                  @click="navigateTo('/')"
                  class="mx-auto cursor"
                  src="@/assets/logo/logo_full.png"
                  max-width="180"
                  max-height="40"
              />
            </v-card-title>
            <v-card-subtitle class="text-h4 text-center mt-6 mb-8 pa-0 black--text">
              <span>홈페이지 관리자 회원가입</span>
            </v-card-subtitle>
            <v-card-text class="pa-0">
              <input type="email" id="email" v-model="email" placeholder="이메일">
              <input type="password" id="password" v-model="password" placeholder="비밀번호">
            </v-card-text>
            <v-col cols="12" class="mt-4 d-flex justify-center primary-blue darken-1 white--text " >
              <input type="submit" value="관리자 회원가입">
            </v-col>
            <div class="text-body-2 grey--text text-center mt-7">
              {{ companyInfo.nameEn }}.
            </div>
          </v-card>
        </form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="1000" color="red">
      {{ error }}
    </v-snackbar>
  </v-container>
</template>

<script>
import rules from '@/constants/rules'
import companyInfo from '@/constants/company_info'
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

export default {
  components: {
  },
  computed: {
    rules() {
      return rules
    },
    companyInfo() {
      return companyInfo[0]
    }
  },
  data() {
    return {
      snackbar: false,
      error: '',
      email: '',
      password: '',
      valid: true,
    }
  },
  methods: {
    // ...mapActions('owner', [SIGN_IN, FETCH_USER]),
    navigateTo(url) {
      this.$router.push(url)
    },
    signUp(){
      firebase
      .auth() 
      .createUserWithEmailAndPassword(this.email, this.password)
      .then(
        () => {
          alert('관리자 회원가입이 완료되었습니다.')
        },
        (err) => {
          alert(`Error - ${err.message}`);
        }
      )
    }
  },
}
</script>

<style lang="scss" scoped>
// .theme--light.v-card {
//   box-shadow: 0px 5px 8px rgba(14, 176, 243, 0.3) !important;
// }

.bg {
  height: 100vh;
  background-color: #FFFFFF;
}
</style>